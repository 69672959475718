import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import LogWindow from './LogWindow';

const LogViewer = () => {
    const [connection, setConnection] = useState(null);
    const [log, setLog] = useState([]);
    const latestLog = useRef(null);

    latestLog.current = log;

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://vpn.digitalmanufacturing.dhl/hubs/log')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');

                    connection.on('ReceiveMessage', message => {
                        const updatedChat = [...latestLog.current];
                        updatedChat.push(message);
                        setLog(updatedChat);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return (
        <div>            
            <LogWindow logmessages={log} />
        </div>
    );
};

export default LogViewer;