import React, { Component } from 'react';
import { VPNStatusList } from './VPNStatusList';

export class ConnectedAdmins extends Component {
    static displayName = ConnectedAdmins.name;

    constructor(props) {
        super(props);
    }


    render() {
        if (this.props.userData.userLoading) {        

            return (
                <div>
                    ... Checking Authentication
                </div>
            );
        }
        else
        {
            if (this.props.userData.userLoggedIn) {

                return (
                    <div>
                        <h2>Admin Connections</h2>
                        <VPNStatusList vpnType={"admins"} disableRouting={true} />
                    </div>
                );
            }
            else {
                return (
                    <div>
                       You need to be logged in to view this page...
                    </div>
                );
            }
        }
  }
}
