import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './VPNStatus.css';

export class VPNStatusBox extends Component {

    constructor(props) {
    super(props);
        this.state = { ...this.props.initialstate, isPinging: false, enablingRouting: false, overrideDisable: this.props.overrideDisableRoutingForAllUnconnectedConnections};
    }

    componentDidMount() {
        if (this.props.pingOnStart) {
            this.pingClick();
        }    
    }  

    componentDidUpdate(prevProps) {
        if (prevProps.overrideDisableRoutingForAllUnconnectedConnections !== this.props.overrideDisableRoutingForAllUnconnectedConnections) {
            this.setState({ overrideDisable: this.props.overrideDisableRoutingForAllUnconnectedConnections })
        }
    }

    render() {
        var isPinging = this.state.isPinging;        
        var enablingRouting = this.state.enablingRouting;
        var isRoutingAllowed = this.state.isRoutingAllowed;                
        var isRouted = this.state.isRouted;
        var disableRoutingButton = (!isRoutingAllowed || enablingRouting || this.state.overrideDisable) && !isRouted;
        var pingDisabled = isPinging;

        const connState = this.state.connectionStateString;
        var iconURL = "";
        var boxStyle = "";

        if (connState == 'PressumedConnected') {
            iconURL = "/images/GreyTick.png";
            boxStyle = "vpnboxgrey";
        }
        else if (connState == 'ConnectionVerified') {
            iconURL = "/images/GreenTick.png";
            boxStyle = "vpnboxgreen";
        }
        else if (connState == 'Disconnected'){
            iconURL = "/images/RedCross.png";
            boxStyle = "vpnboxred";
            pingDisabled = true;
        }
        else if (connState == 'PingNoReply') {
            iconURL = "/images/YellowWarn.png";
            boxStyle = "vpnboxyellow";
        }

        var pingButtonText = "Ping"
        if (isPinging) {
            pingButtonText = "Pinging..."
        }
        else {
            if (connState == 'ConnectionVerified') {
                pingButtonText += " [" + this.state.pingTime + " ms]";
            }
            else if (connState == 'PingNoReply') {
                pingButtonText += " failed";
            }
        }

        var routeButtonText = " Create Route"
        if (enablingRouting) {
            routeButtonText = " Routing "
        }
        else if (isRouted) {
            routeButtonText = " Routed "
        }

        return (
            <div id={boxStyle}>
                <div class="vpnHeaderText"><img src={iconURL} width="24px" height="24px" /> {this.state.name}</div>
                <hr class="hrvpntop" />
                <div class="detailHeader">Tunnel IP: <font class="detailText">{this.state.tunnelIP}</font></div>
                <div class="detailHeader">Origin IP: <font class="detailText">{this.state.originIP}</font> </div>
                <div class="detailHeader">Internal: <font class="detailText">{this.state.networkSubnet}</font></div>
                <div class="detailHeader">Total Down: <font class="detailText">{this.state.totalTrafficDown}</font> Total Up: <font class="detailText">{this.state.totalTrafficUp}</font></div>
                <div class="detailHeader">Connected for <font class="detailText">{this.state.connectionDuration}</font></div>
                <hr class="hrvpnbottom" />

                <Button color="secondary" size="sm" disabled={pingDisabled} onClick={!isPinging ? () => this.pingClick() : null} > {pingButtonText}</Button>
                <span class="floatright" ><Button color={isRouted ? "primary" : "secondary"} size="sm" disabled={disableRoutingButton} onClick={() => this.routeClick()}>{routeButtonText}</Button></span>
            </div>
        );    
    }

    async pingClick() {
        if (this.state.connectionStateString != 'Disconnected') {
            this.setState({ isPinging: true });
            this.attemptPing();
        }
    }

    async routeClick() {
        this.setState({ enablingRouting: true });
        this.attemptRouting();
    }


    async attemptPing() {
        const response = await fetch(`ping?ip=${this.state.tunnelIP}`);
        const data = await response.json();               
        this.setState({ connectionState: data.connectionState, connectionStateString: data.connectionStateString, pingTime: data.pingTime, isPinging: false });
    }

    async attemptRouting() {
        const response = await fetch(`routing?id=${this.state.id}&connectBoolString=${!this.state.isRouted}`);
        const data = await response.json();
        this.setState({ isRouted: data.isRouted, enablingRouting: false });
        
        if (data.success) {
            this.props.setRoutingOverrideForAllComponents(data.isRouted);
        }
    }
}
