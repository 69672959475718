import React from 'react';
import { NavLink } from 'reactstrap';
import './NavMenu.css';
import { Link } from 'react-router-dom';

export class SignInSignOutButton extends React.Component {

    constructor(props) {
        super(props);        
    }
    
    render() {        

        if (this.props.userData.userLoggedIn) {
            return (
                <a className="text-dark nav-link" href="MicrosoftIdentity/Account/SignOut">{this.props.userData.userDisplayName} (Sign-Out)</a>
        );
        } else {
            return (
                <a className="text-dark nav-link" href="/MicrosoftIdentity/Account/SignIn"> Sign-in</a>
            );
        }
    }
}
