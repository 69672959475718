import React, { Component } from 'react';
import LogViewer from './logecho/LogViewer'

export class StatusLog extends Component {
    static displayName = StatusLog.name;

    constructor(props) {
        super(props);
    }


    render() {
        if (this.props.userData.userLoading) {        

            return (
                <div>
                    ... Checking Authentication
                </div>
            );
        }
        else
        {
            if (this.props.userData.userLoggedIn) {

                return (
                    <div>
                        <h2>Live Log</h2>
                        <LogViewer />
                    </div>
                );
            }
            else {
                return (
                    <div>
                       You need to be logged in to view this page...
                    </div>
                );
            }
        }
  }
}
