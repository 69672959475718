import React, { Component, useContext, Redirect } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { ConnectedAdmins } from './components/ConnectedAdmins';
import { StatusLog } from './components/StatusLog';
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { loading: true, userData: { userLoggedIn: false, userDisplayName: "", userFirstName: "", userLoading: true } };
    }

    componentDidMount() {
        this.populateLoginData();
    }

    async populateLoginData() {
        const response = await fetch('userdetails');
        const data = await response.json();        

        this.setState({
            userData: {
            userLoggedIn: data.userLoggedIn,
            userHasAccess: data.userHasAccess,
            userDisplayName: data.userDisplayName,
            userFirstName: data.userFirstName,
            userLoading: false
        }
        });       
    }
    
  render () {
      return (              
          <Layout userData={this.state.userData}>                        
              <Route exact path='/' render={(props) => (<Home {...props} userData={this.state.userData} />)} />   
              <Route exact path='/connected_admins' render={(props) => (<ConnectedAdmins {...props} userData={this.state.userData} />)} />
              <Route exact path='/status_log' render={(props) => (<StatusLog {...props} userData={this.state.userData} />)} />                 
          </Layout>
    );
    }    
}