import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './VPNStatus.css';
import { VPNStatusBox } from './VPNStatusBox';

export class VPNStatusList extends Component {

    constructor(props) {
    super(props);
        this.state = { vpndetails: [], vpnLogLastUpdateTime: 0, loading: true, pingOnStart: false, overrideDisableRoutingForAllUnconnectedConnections: this.props.disableRouting };
    }

    componentDidMount() {
        this.populateVPNList();
        this.interval = setInterval(() => this.setState({ vpnLogLastUpdateTime: this.state.vpnLogLastUpdateTime + 1 }), 1000);
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setRoutingOverrideForAllComponents = enabled => {       
        this.setState({ overrideDisableRoutingForAllUnconnectedConnections: enabled});
    }    
    
    static renderVPNList(vpndetails, pingOnStart, disableRoutingForAllConnections, setRoutingOverrideForAllComponents, overrideDisableRoutingForAllUnconnectedConnections) {
        return (
            <div id="flex-container">
                {vpndetails.map(vpnstatus => <VPNStatusBox initialstate={vpnstatus} pingOnStart={pingOnStart} setRoutingOverrideForAllComponents={setRoutingOverrideForAllComponents} overrideDisableRoutingForAllUnconnectedConnections={overrideDisableRoutingForAllUnconnectedConnections}/>)}
            </div>
        );
    }

    render() {                  
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : VPNStatusList.renderVPNList(this.state.vpndetails, this.state.pingOnStart, this.state.disableRoutingForAllConnections, this.setRoutingOverrideForAllComponents, this.state.overrideDisableRoutingForAllUnconnectedConnections);

        return (
            <div>                
                <p>VPN connections
                    <span class="floatright" >Updated {this.state.vpnLogLastUpdateTime} seconds ago&ensp;
                        <Button color="outline-info" size="sm" disabled={this.state.loading} onClick={() => this.populateVPNList()}> Refresh </Button>&emsp;
                        <Button color="outline-secondary" size="sm" active={this.state.pingOnStart} onClick={() => this.setState({ pingOnStart: !this.state.pingOnStart })}> Auto Ping on Refresh </Button>
                    </span></p>
                <hr />                
                {contents}                    
                {this.newdata}
            </div>            
        );
    }

  async populateVPNList() {
      const response = await fetch(`vpnstatus?type=${this.props.vpnType}`);
      const data = await response.json();
      this.setState({ vpndetails: [] });
      this.setState({ vpndetails: [...data.vpnDetails], vpnLogLastUpdateTime: data.secondsSinceLogWasUpdated, loading: false, newdata: true });      
  }
}