import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SignInSignOutButton } from './SignInSignOutButton';
import './NavMenu.css';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <img src="/images/DM.png" />&emsp;
                        <NavbarBrand tag={Link} to="/">OpenVPN Controller</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {this.props.userData.userLoggedIn && (<NavItem><NavLink tag={Link} className="text-dark" to="/">Clients</NavLink></NavItem>) }
                                {this.props.userData.userLoggedIn && (<NavItem><NavLink tag={Link} className="text-dark" to="/connected_admins">Admins</NavLink></NavItem>)}
                                {this.props.userData.userLoggedIn && (<NavItem><NavLink tag={Link} className="text-dark" to="/status_log">Status Log</NavLink></NavItem>)}                                
                                <SignInSignOutButton userData={this.props.userData} />
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
