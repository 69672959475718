import React, { Component } from 'react';
import { VPNStatusList } from './VPNStatusList';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);        
        this.state = { loading: true, ip: "", isRoutable: false };        
    }

    componentDidMount() {
        this.GetIPData();
    }

    async GetIPData(){
        const response = await fetch('ipdetails');
        const data = await response.json();

        this.setState({
                ip: data.address,
                isRoutable: data.isRoutable,
                loading: false            
                });
    }

    render() {
    if (this.props.userData.userLoading || (this.state.loading && this.props.userData.userHasAccess)) {
            return (
                <div>
                    ... Checking Authentication and Loading
                </div>
            );
        }
        else
        {
            if (this.props.userData.userLoggedIn && this.props.userData.userHasAccess) {

                return (
                    <div>
                        <h1>Hello, {this.props.userData.userFirstName}</h1>
                        <p>Your IP address is <strong>{this.state.ip}</strong>, which is {this.state.isRoutable ? "Routable" : <strong>Not Routable</strong>} </p>
                        <VPNStatusList vpnType={"clients"} disableRouting={!this.state.isRoutable} />
                    </div>
                );
            }
            else {
                if (this.props.userData.userLoggedIn) {
                    return (
                        <div>
                            <p>Hello, {this.props.userData.userFirstName}.</p>
                            <p><strong>Sorry you do not have access to this system.</strong></p>
                        </div>
                    );
                }
                else {
                    return (
                        <div>
                            You need to be logged in to view this page...
                        </div>
                    );
                }
            }
        }
  }
}
