import React from 'react';

import Message from './LogMessage';

const LogWindow = (props) => {
    const logmessages = props.logmessages
        .map(m => <Message
            key={Date.now() * Math.random()}
            logLevel={m.logLevel}
            message={m.message} />);

    return (
        <div>
            {logmessages}
        </div>
    )
};

export default LogWindow;